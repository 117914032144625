import React, { memo } from "react"
import { Link } from "gatsby"
import Image from "../components/image"

const Desktop = ({ sites }) => {
  return (
    <>
      <div className="thumbs">
        {sites.map((site, i) => {
          if (i < 2) {
            return (
              <div className="thumb x2" key={`${site.node.mysqlId}msid`}>
                <div className="th">
                  <Link to={`/${site.node.site_url}`}>
                    <Image
                      alt={site.node.site_name}
                      filename={`${site.node.site_url}.jpg`}
                    />
                    <div className="inform">
                      <div className="column">
                        {site.node.sitestat.likes} <span>likes</span>
                      </div>
                      <div className="column">
                        {site.node.sitestat.videos} <span>videos</span>
                      </div>
                    </div>
                  </Link>
                  <div className="field_more">
                    <Link to={`/${site.node.site_url}`} className="button_more">
                      <span>Access my video library + direct messaging</span>
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div className="thumb x3" key={`${site.node.mysqlId}msid`}>
              <div className="th">
                <Link to={`/${site.node.site_url}`}>
                  <Image
                    alt={site.node.site_name}
                    filename={`${site.node.site_url}.jpg`}
                  />
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div className="bottom_more">
        much <strong>more</strong> coming soon...
      </div>
    </>
  )
}

export default memo(Desktop, () => true)
